import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import {
  Carousel,
  Accomplishments,
  SectionHeader,
  HorizontalTimeline,
  VerticalTimeline,
  QuotesBar,
  FullWidthBarWithLeaves,
  Button,
} from '@components';

import styles from './aboutPage.module.scss';

const About = () => {
  const query = useStaticQuery(graphql`
    query {
      allStrapiAboutPage {
        nodes {
          carousel {
            id
            description
            mobileDescription
            heroHeader {
              fullSizedTitle
              id
              miniTitle
            }
            isAltLayout
            heroImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 650) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          accomplishmentsSectionHeader {
            id
            subtitleDesktop
            subtitleMobile
            title
          }
          accomplishments {
            count
            description
            id
            list {
              id
              listItem
            }
          }
          workExperienceSectionHeader {
            subtitleDesktop
            subtitleMobile
            title
          }
          workExperienceTimeline {
            date
            description
            function
            id
            location
            title
            subtitle
            website
            tags {
              id
              tag
            }
            images {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 650) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          educationSectionHeader {
            subtitleDesktop
            subtitleMobile
            title
          }
          educationTimeline {
            description
            function
            id
            location
            tags {
              tag
              id
            }
            title
            subtitle
            images {
              id
              localFile {
                childImageSharp {
                  fluid(maxWidth: 650) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          otherLearningsSectionHeader {
            subtitleDesktop
            title
            subtitleMobile
          }
          otherLearningsHorizontalTimeline {
            date
            id
            location
            title
          }
        }
      }
    }
  `);

  const {
    carousel,
    accomplishmentsSectionHeader,
    accomplishments,
    workExperienceSectionHeader,
    workExperienceTimeline,
    educationSectionHeader,
    educationTimeline,
    otherLearningsSectionHeader,
    otherLearningsHorizontalTimeline,
  } = query.allStrapiAboutPage.nodes[0];

  return (
    <>
      <section className={styles.section}>
        <Carousel carouselData={carousel} />
      </section>
      <section>
        <SectionHeader data={accomplishmentsSectionHeader} />
        <Accomplishments accomplishmentsData={accomplishments} />
        <FullWidthBarWithLeaves>
          <div className={styles.resumeCVBtn}>
            <Button
              title='Download my 1-Page Resume'
              arrowDirection='down'
              onClick={() =>
                window.open(
                  `mailto:${process.env.GATSBY_CONTACT_EMAIL}?&subject=Resume%20Request&body=Hello%20Jolyn,%20may%20I%20have%20a%20copy%20of%20your%20resume%20please!`
                )
              }
            />
          </div>
          <div className={styles.resumeCVBtn}>
            <Button
              title='Download my full-length CV'
              arrowDirection='down'
              onClick={() =>
                window.open(
                  `mailto:${process.env.GATSBY_CONTACT_EMAIL}?&subject=CV%20Request&body=Hello%20Jolyn,%20may%20I%20have%20a%20copy%20of%20your%20CV%20please!`
                )
              }
            />
          </div>
        </FullWidthBarWithLeaves>
      </section>
      <section>
        <SectionHeader data={workExperienceSectionHeader} />
        <VerticalTimeline content={workExperienceTimeline} />
      </section>
      <section>
        <SectionHeader data={educationSectionHeader} />
        <VerticalTimeline content={educationTimeline} />
      </section>
      <section>
        <QuotesBar
          author='Japanese Proverb'
          quote='Work of self. Obtainment of self.'
        />
      </section>
      <section>
        <SectionHeader data={otherLearningsSectionHeader} classNames={styles} />
      </section>

      <HorizontalTimeline timelineData={otherLearningsHorizontalTimeline} />
    </>
  );
};

export default About;
